import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { ConfirmAttendanceGQL } from 'graphql/generated';

@Component({
    selector: 'app-event-validation',
    standalone: true,
    imports: [MatIconModule, CommonModule],
    templateUrl: './event-validation.component.html',
    styleUrl: './event-validation.component.scss',
})
export class EventValidationComponent implements OnInit {
    invitationToken!: string;
    eventAttendanceId!: string;
    validationEffectif: boolean | null = null;

    constructor(
        private route: ActivatedRoute,
        private confirmAttendance: ConfirmAttendanceGQL
    ) {}

    ngOnInit(): void {
        // Récupérer les query params de l'URL
        this.route.queryParams.subscribe((params) => {
            this.eventAttendanceId = params['eventAttendanceId'];
            this.invitationToken = params['invitationToken'];

            this.confirmAttendance
                .mutate({
                    eventAttendanceId: this.eventAttendanceId,
                    token: this.invitationToken,
                })
                .subscribe({
                    next: (resp) => {
                        if (resp.data?.confirmAttendance) {
                            this.validationEffectif = true;
                        } else {
                            this.validationEffectif = false;
                        }
                    },
                    error: () => {
                        this.validationEffectif = false;
                    },
                });
        });
    }
}
