<div class="min-w-screen flex flex-col items-center justify-center min-h-screen bg-primary-500 text-white p-6">
    <!-- Logo -->
    <div class="mb-8">
        <img src="images/logo/visi_pass_logo.png" alt="Logo" class="w-28 h-28 object-contain" />
    </div>

    <ng-container *ngIf="validationEffectif !== null; else loadingState">
        <div *ngIf="validationEffectif; else errorState"
            class="bg-white text-gray-900 rounded-lg shadow-lg p-6 text-center max-w-md">
            <!-- Icon -->
            <div
                class="flex items-center justify-center w-16 h-16 bg-green-100 text-green-600 rounded-full mx-auto mb-4">
                <mat-icon class="text-6xl h-11 w-11">check</mat-icon>
            </div>

            <!-- Confirmation Message -->
            <h1 class="text-2xl font-bold mb-2">Invitation confirmée !</h1>
            <p class="text-gray-700 mb-4">
                Merci d'avoir confirmé votre présence à l'événement. Nous avons bien enregistré votre réponse et vous
                contacterons sous peu.
            </p>

            <!-- Button -->
            <a href="/"
                class="mt-4 inline-block bg-primary-600 hover:bg-primary-700 text-white font-semibold rounded-lg px-6 py-2 transition duration-300">
                Retour à l'accueil
            </a>
        </div>

        <!-- État d'échec -->
        <ng-template #errorState>
            <div class="bg-white text-gray-900 rounded-lg shadow-lg p-6 text-center max-w-md">
                <!-- Icon -->
                <div
                    class="flex items-center justify-center w-16 h-16 bg-red-100 text-red-600 rounded-full mx-auto mb-4">
                    <mat-icon class="text-6xl h-11 w-11">close</mat-icon>
                </div>

                <!-- Error Message -->
                <h1 class="text-2xl font-bold mb-2">Validation échouée</h1>
                <p class="text-gray-700 mb-4">
                    Nous n'avons pas pu confirmer votre présence à l'événement. Veuillez réessayer ou contacter
                    l'organisateur.
                </p>

                <!-- Button -->
                <a href="/"
                    class="mt-4 inline-block bg-primary-600 hover:bg-primary-700 text-white font-semibold rounded-lg px-6 py-2 transition duration-300">
                    Retour à l'accueil
                </a>
            </div>
        </ng-template>
    </ng-container>

    <!-- État de chargement -->
    <ng-template #loadingState>
        <div class="text-center">
            <p class="text-lg font-semibold">Chargement en cours...</p>
        </div>
    </ng-template>
</div>